import React from "react"
import Layout from "../components/Layout/layout"
import translationHelper from "../i18n/helper"
import parse from "html-react-parser"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import EmptySpaceSeperator from "../components/Misc/EmptySpaceSeperator/emptySpaceSeperator"
import SEO from "../components/seo"

const privacyPolicy = ({ pageContext: { locale }, path }) => {

  return (
    <Layout locale={locale} path={path}>
        <SEO title="Mybusiness360 Privacy Policy"
            lang={locale}
            description={translationHelper[locale]["companyDescription"]}
            />
      <Container>
        <Row>
          <Col md={12}>
            <EmptySpaceSeperator/>

            {parse(translationHelper[locale]["privacyPolicyText"])}
          </Col>
        </Row>
      </Container>

    </Layout>
  )
}

export default privacyPolicy

